import React, { Fragment, useState, useEffect } from "react";
import "../Components/styles/launcherPage.css";
import CarouselBoothstrapComponent from "../Components/LauncherPageComponent/carouselBoothstrapComponent";
import { imagesLinks, socialLinks } from "../Constants/links";
import SDK from "../config";
import axios from "axios";
import {
  AddictiveAppCards,
  FooterSection,
  NewportSection,
  TimeLimitCards,
  WelcomeScreenCards,
} from "../Components/LauncherPageComponent/launcherPageComponent";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import TrackingScripts from "../Components/checkoutComponent/trackingComponent";

const LauncherPage = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isWidthView, setIsWidthView] = useState(false);
  const eventId = `event-${new Date().getTime()}`;

  useEffect(() => {
    const handleResize = () => {
      setIsWidthView(window.innerWidth <= 650);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "Lead", { event_id: eventId });
    }

    if (window.ttq) {
      window.ttq.track("Pageview", { event_id: eventId });
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const earlyAccessClick = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      toast.error("Please enter an email.");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Email not valid");
      return;
    }
    const tagsString = "Android Website";
    const queryParams = new URLSearchParams({
      email_address: email,
      status: "subscribed",
      tags: tagsString,
      AddToCart: "1",
    });

    const queryString = queryParams.toString();
    axios
      .post(`${SDK.BASE_URL}/api/post_email_mailchimp?${queryString}`)
      .then((response) => {
        // console.log("Email saved:", response);
      })
      .catch((error) => {
        console.error("Error saving email:", error);
      });

    if (window.fbq) {
      window.fbq("track", "AddToCart");
    }

    if (window.ttq) {
      window.ttq.track("AddToCart");
    }

    navigate(`/reservation?email=${encodeURIComponent(email)}`);
  };

  const handleIOSCLick = () => {
    window.open(socialLinks.iOSPageLink, "_blank");
  }

  return (
    <Fragment>
      <Toaster />
      <TrackingScripts />
      <div className="overflow-x-hidden">
        <div className="minimis_launcher">
          <div className="launcher_navbar d-flex justify-content-between">
            <div>
              <img
                src={imagesLinks.logo}
                className="navLogo"
                style={{
                  paintOrder: "stroke fill",
                  fill: "black",
                  "-webkit-text-stroke": "12px black",
                  height: "28px",
                  width: "179px",
                }}
                alt="Logo"
              />
            </div>
          </div>

          <div className="phone_mockup_section">
            <section className="section1">
              <div>
                <p className="p1">Minimal Distractions. Maximal Life.</p>
                <p className="p2">
                  Minimis Launcher brings your phone back to basics. You control
                  your phone. Not the other way around.
                </p>
              </div>
              <div className="mt-5 emailDiv w-100">
                <form onSubmit={earlyAccessClick} className="pt-4 d-flex">
                  <div>
                    <input
                      type="text"
                      placeholder="Your email..."
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <button type="submit">Get access now</button>
                </form>
              </div>
            </section>
            <section className="section2">
              <div>
                <img src={imagesLinks.androidMinimisLauncher} alt="Android Mockup" />
              </div>
            </section>
          </div>
        </div>

        <div className="addictiveApp_cards">
          <AddictiveAppCards />
        </div>

        <div className="call_newport_section">
          <NewportSection />
        </div>

        <div className="timeLimit_cards">
          <TimeLimitCards />
        </div>
        <div className="early_access_cards">
          <div>
            <p className="p1">Ready to jump right in?</p>
          </div>
          <div className="d-flex">
            <div>
              <input
                type="text"
                placeholder="Your email..."
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            {isWidthView ? (
              <button onClick={earlyAccessClick}>Get Started</button>
            ) : (
              <button onClick={earlyAccessClick}>Change your life now!</button>
            )}
          </div>
        </div>
        <div className="welcome_screen_cards">
          <WelcomeScreenCards />
        </div>

        <div className="android-main-container">
          <div className="android-google-container">
            <div className="android-get-google">
              <div className="android-get-google-text">
                <h3>iOS User?</h3>
                <p>
                  We're in beta testing! Early-access through Beta testing is
                  included with Lifetime Subscriptions.
                </p>
              </div>
              <div
                className="android-get-google-image"
                style={{ cursor: "pointer" }}
              >
                <button onClick={handleIOSCLick}>View our iOS page</button>
              </div>
            </div>
            <div className="android-google-mobile">
              <img src={imagesLinks.iosRewviewimage} alt="" />
            </div>
          </div>
        </div>
        <div className="android-download-review">
          <p className="android-download-review-heading">
            What our users are saying about us
          </p>
          <CarouselBoothstrapComponent />
        </div>

        <div className="footer_section">
          <FooterSection />
        </div>
      </div>
    </Fragment>
  );
};

export default LauncherPage;
